import { useLocation } from "react-router-dom";
import { Home } from "./Home";

export const Container = ({ children }) => {

  const location = useLocation();

  return <>
    {false && location.pathname !== "/" && <Home />}
    <div class="container mx-auto px-4">
      <div class="flex flex-col justify-center items-center min-h-screen gap-8">
        {children}
      </div>
    </div>
  </>;
};
