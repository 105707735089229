import { useNavigate } from "react-router-dom";

export const Index = () => {
  const navigate = useNavigate();

  return (
    <>
      <h1 class="text-4xl lg:tracking-widest">
        Täällä voit arvioida rokotustarpeesi
      </h1>

      <div class="relative w-80 h-80">
        <img src="influenssa.png" alt="influenssa" />
        <div className="absolute bottom-1/4 right-4">
          <button
            className={`bg-white hover:bg-sky-100 px-4 py-3 `}
            onClick={() => navigate("/age")}
          >
            Influenssa &gt;
          </button>
        </div>
      </div>

      <span class="text-sm pt-8">
        Rokotusarvio perustuu THL:n suosituksiin. Tarkista aina ajantasaiset
        tiedot{" "}
        <a href="https://thl.fi/aiheet/infektiotaudit-ja-rokotukset/rokotteet-a-o/influenssarokote">
          THL:n sivustolta
        </a>
        .
      </span>

      <button
        onClick={() => navigate("/info")}
        className="text-center mt-8 text-base flex items-center justify-center gap-1"
      >
        LISÄTIETOJA <img src="mail.png" alt="mail" className="w-6 h-6" />
      </button>
      <span class="text-sm text-gray-800">
        ©️ 2025 Rokotearvio.fi. Kaikki oikeudet pidätetään.{" "}
        <a href="/privacy">Tietosuojaseloste</a>
      </span>
    </>
  );
};
