import React from "react";
import { SearchInput } from "../Components/SearchInput";

export const Search = () => {
  return (
    <>
      <h1>Hae hyvinvointialueesi rokotuspisteen yhteystiedot</h1>

      <SearchInput />
    </>
  );
};
