import React from "react";
import { useNavigate } from "react-router-dom";
import { Button } from "../../Components/Button";

export const Children = () => {
  const navigate = useNavigate();

  return (
    <>
      <span>Influenssarokotetta suositellaan alle 7-vuotiaille lapsille. Rokote on maksuton alle 7-vuotiaille lapsille.</span>

      <a
        href="https://thl.fi/aiheet/infektiotaudit-ja-rokotukset/rokotteet-a-o/influenssarokote/vasta-aiheet-ja-varotoimet-influenssarokotuksissa"
        target="_blank"
        rel="noreferrer"  style={{ color: "25b7d3", textDecoration: "underline", cursor: "pointer" }}
      >
        VARMISTA AINA, ONKO ROKOTTAMISELLE VASTA-AIHEITA
      </a>

      <Button onClick={() => navigate("/search")}>SEURAAVA &gt;</Button>
    </>
  );
};
