import React from "react";
import { Button } from "../Components/Button";

export const Privacy = () => {
  return (
    <>
      <h1 class="text-4xl lg:tracking-widest">Tekijänoikeus ja käyttöehdot</h1>
      <p className="text-center">
        ©️ 2025 Rokotearvio.fi. Kaikki oikeudet pidätetään.
      </p>
      <p className="text-center">
        Kaikki tällä sivustolla julkaistu sisältö, mukaan lukien tekstit, kuvat,
        grafiikat, laskurit ja muu aineisto, on suojattu tekijänoikeuslain
        mukaisesti.
      </p>
      <h1 class="text-4xl lg:tracking-widest">Sivuston käyttö</h1>
      <p className="text-center">
        <ul>
          <li>
            Rokotearvio.fi tarjoaa informatiivista sisältöä rokotearvioista ja
            siihen liittyvistä palveluista.
          </li>
          <li>
            ⁠Sivuston sisältöä saa käyttää vain henkilökohtaiseen ja
            ei-kaupalliseen tarkoitukseen.
          </li>
          <li>
            ⁠Sisällön kopiointi, muokkaaminen, jakaminen tai kaupallinen
            hyödyntäminen ilman kirjallista lupaa on kielletty.
          </li>
        </ul>
      </p>
      <h1 class="text-4xl lg:tracking-widest">
        Tekijänoikeus ja aineiston käyttö
      </h1>
      <p className="text-center">
        <ul>
          <li>Sivuston omistajat pidättävät kaikki oikeudet sisältöön.</li>
          <li>
            Mikäli haluatte käyttää sisältöämme esimerkiksi koulutus- tai
            tiedotustarkoituksiin, ottakaa yhteyttä saadaksenne luvan.
          </li>
          <li>
            Mahdolliset tekijänoikeusloukkaukset voivat johtaa oikeudellisiin
            toimenpiteisiin.
          </li>
        </ul>
      </p>
      <h1 class="text-4xl lg:tracking-widest">Vastuuvapauslauseke</h1>
      <p className="text-center">
        <ul>
          <li>
            Sivuston tiedot pyritään pitämään ajan tasalla, mutta emme takaa
            niiden täydellistä oikeellisuutta tai soveltuvuutta yksilöllisiin
            terveydenhuollon päätöksiin.
          </li>
          <li>
            Rokotearvio.fi ei korvaa terveydenhuollon ammattilaisen antamaa
            neuvontaa.
          </li>
        </ul>
      </p>

      <Button onClick={() => window.history.back()}>Takaisin</Button>
    </>
  );
};
