//import { useNavigate } from "react-router-dom";

export const Home = ({ children }) => {
  //const navigate = useNavigate();

  return <div className="px-2 py-2">
    
    <button
      className={`bg-white hover:bg-sky-100  border-stone-400 px-1 py-1 text-lg underline`}
    >
      &lt; Edellinen
    </button>

  </div>;
};
