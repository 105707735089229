import React from "react";
import { useNavigate } from "react-router-dom";
import { ButtonGroup } from "../../../Components/ButtonGroup";
import { Button } from "../../../Components/Button";

export const Sick = () => {
  const navigate = useNavigate();

  return (
    <>
      <span>
        Kuulutko sairauden tai hoidon vuoksi{" "}
        <a 
          href="https://thl.fi/aiheet/infektiotaudit-ja-rokotukset/rokotteet-a-o/influenssarokote/kenelle-ilmainen-influenssarokote-/sairauden-tai-hoidon-vuoksi-riskiryhmaan-kuuluvien-influenssarokotukset"
          style={{ color: "25b7d3", textDecoration: "underline", cursor: "pointer" }}
          >
          riskiryhmään
        </a>
      </span>

      <ButtonGroup>
        <Button onClick={() => navigate("/age/adults")} width={true}>
          KYLLÄ
        </Button>
        <Button onClick={() => navigate("/age/adults/riskgroup")} width={true}>
          EN
        </Button>
      </ButtonGroup>
    </>
  );
};
