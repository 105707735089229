import React from "react";
import { useNavigate } from "react-router-dom";
import { Button } from "../../../Components/Button";

export const PregnantOk = () => {
  const navigate = useNavigate();

  return (
    <>
      <span className="block text-center"> 
        Influenssarokotetta suositellaan raskaana oleville. Olet oikeutettu maksuttomaan rokotteeseen. <br />Myös lähipiirisi saa
        maksuttoman influenssarokotteen.
      </span>

      <a style={{ color: "25b7d3", textDecoration: "underline", cursor: "pointer" }}
        href="https://thl.fi/aiheet/infektiotaudit-ja-rokotukset/rokotteet-a-o/influenssarokote/vasta-aiheet-ja-varotoimet-influenssarokotuksissa"
        target="_blank"
        rel="noreferrer"
      >
        VARMISTA AINA, ONKO ROKOTTAMISELLE VASTA-AIHEITA
      </a>

      <Button onClick={() => navigate("/search")}>SEURAAVA &gt;</Button>
    </>
  );
};
